import { graphql } from 'gatsby'

import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { Trustpilot } from '../components/Widgets'
import { PageLayout } from '../components/Layout'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const FerryCrossingOnboardExperienceRelatedPage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    textAndImage1ContentReference,
    optionaltextAndGalleryContentReference,
    optionalTextAndImage1ContentReference,
    onboardExperienceLinkboxSlider,
    optionalTextAndImage2ContentReference,
    textAndImage2ContentReference,
    trustpilotRef,
    additionalTrustpilotRef,
    optionalNewsletterSubscribeBlock,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={miniBookReference} />

          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        reverse
        makeTitleAnH1
        title={textAndImage1ContentReference.title}
        text={textAndImage1ContentReference.text}
        images={textAndImage1ContentReference.imagesCollection.items}
        links={textAndImage1ContentReference.linksCollection.items}
        {...textAndImage1ContentReference}
      />
      {optionaltextAndGalleryContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          title={optionaltextAndGalleryContentReference.title}
          text={optionaltextAndGalleryContentReference.text}
          images={optionaltextAndGalleryContentReference.imagesCollection.items}
          links={optionaltextAndGalleryContentReference.linksCollection.items}
          {...optionaltextAndGalleryContentReference}
        />
      )}
      {optionalTextAndImage1ContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse
          title={optionalTextAndImage1ContentReference.title}
          text={optionalTextAndImage1ContentReference.text}
          images={optionalTextAndImage1ContentReference.imagesCollection.items}
          links={optionalTextAndImage1ContentReference.linksCollection.items}
          {...optionalTextAndImage1ContentReference}
        />
      )}
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="ferryCrossingOnboardExperienceRelatedPage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      <LinkboxCarousel {...onboardExperienceLinkboxSlider} />
      {optionalTextAndImage2ContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse
          title={optionalTextAndImage2ContentReference.title}
          text={optionalTextAndImage2ContentReference.text}
          images={optionalTextAndImage2ContentReference.imagesCollection.items}
          links={optionalTextAndImage2ContentReference.linksCollection.items}
          {...optionalTextAndImage2ContentReference}
        />
      )}
      {additionalTrustpilotRef && (
        <Trustpilot
          {...additionalTrustpilotRef}
          template="ferryCrossingOnboardExperienceRelatedPage"
          id={sys.id}
          item="additionalTrustpilotRef"
        />
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        title={textAndImage2ContentReference.title}
        text={textAndImage2ContentReference.text}
        images={textAndImage2ContentReference.imagesCollection.items}
        links={textAndImage2ContentReference.linksCollection.items}
        {...textAndImage2ContentReference}
      />
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock {...optionalNewsletterSubscribeBlock} />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
    </PageLayout>
  )
}

export const query = graphql`
  query FerryCrossingOnboardExperienceRelatedPage(
    $id: String!
    $locale: String!
  ) {
    content: contentful {
      page: ferryCrossingOnboardExperienceRelatedPage(
        id: $id
        locale: $locale
      ) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
          ...MinibookProductList
        }
        textAndImage1ContentReference {
          ...TextAndMediaContent
        }
        optionaltextAndGalleryContentReference {
          ...TextAndMediaContent
        }
        optionalTextAndImage1ContentReference {
          ...TextAndMediaContent
        }
        onboardExperienceLinkboxSlider {
          ...LinkboxCarousel
        }
        optionalTextAndImage2ContentReference {
          ...TextAndMediaContent
        }
        textAndImage2ContentReference {
          ...TextAndMediaContent
        }
        trustpilotRef {
          ...Trustpilot
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default FerryCrossingOnboardExperienceRelatedPage
